const React = require("react"),
    PropTypes = require('prop-types');
const Utility = require("util/Utility");
const {AdvancedMarker } = require('@vis.gl/react-google-maps');

const BasicMapMarker = ({coordinate, icon, dragEndCallback}) => {
    function dragEnd(event) {
        let tLat = Utility.reduceDecimals(event.latLng.lat(), 100000);
        let tLong = Utility.reduceDecimals(event.latLng.lng(), 100000);
        if (dragEndCallback) {
            dragEndCallback(tLat, tLong)
        }

    }
    const posMarker = {lat:  coordinate.latitude, lng: coordinate.longitude};
    return  <AdvancedMarker  position={posMarker} draggable={dragEndCallback != null} onDragEnd={dragEnd}>
        { icon ? <img src={icon} width={32} height={37} /> : null}
    </AdvancedMarker>
}
BasicMapMarker.propTypes = {
    coordinate: PropTypes.object.isRequired,
    dragEndCallback:PropTypes.func,
    icon: PropTypes.object
};
module.exports = BasicMapMarker;