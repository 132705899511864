const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col,Grid, Label, ButtonGroup, Button, Image, PageHeader, Alert} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ClubCreateModal = require("components/clubmodal/ClubCreateModal"),
    LoadingCircle = require("components/widgets/LoadingCircle"),
    ClubLoader = require("components/club/ClubLoader"),
    ClubCalendar = require("components/club/ClubCalendar"),
    ClubPendingTable = require("components/club/ClubPendingTable"),
    ClubAddBusinessAccount = require("components/club/ClubAddBusinessAccount");

const clubActions = require("actions/ClubActions"),
    userActions = require("actions/UserActions");

const ClubUser = require("parse/ClubUser");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ClubDetailsPage extends React.Component {
    render() {
        // Club details will be loaded as soon as club and clubUsers will be loaded
        return <ClubLoader clubID={this.props.clubID} clubUserRole={null} loginMandatory={false}>
            <ClubDetails />
        </ClubLoader>
    }
}
ClubDetailsPage.propTypes = {
    clubID: PropTypes.string.isRequired
};
const ClubDetails = ({club, user, ownClubUser, ownClubUserLoaded, clubUsers}) => {
    let showAdd2Management = false;
    if (ownClubUser != null && !club.hasManagementID()) {
        showAdd2Management = ownClubUser.hasClubOwner();
    }
    document.title = club.getName();
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/club" code="header.nav.clubs"/>
                            <Breadcrumb code={club.getName()} active/>
                        </Breadcrumbs>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <ClubHeader club={club} />
                </Col>
                {
                    !club.isEmailVerified() &&  ownClubUser != null && ownClubUser.hasClubOwner() ? <Col lg={12}>
                            <Alert bsStyle="warning">
                                <I18n code="club.mailNotVerified"/><br/>
                                <Button bsStyle="primary" onClick={() => clubActions.resendClubAuthenticationMail(club)}>
                                    <FontAwesome icon="envelope"/>{"Authentication mail to " + club.getEmail() }</Button>
                            </Alert>
                        </Col> : null
                }
            </Row>
            <Row>
                <Col sm={3} xs={12} smPush={9}>
                    {
                        ownClubUserLoaded ? <ClubButtons
                            user={user}
                            ownClubUser={ownClubUser}
                            club={club}/> : <LoadingCircle/>
                    }

                </Col>
                <Col sm={9} xs={12} smPull={3}>
                    {
                        user && ownClubUser ? <ClubPendingTable
                            ownClubUser={ownClubUser}
                            club={club}
                            clubUsers={clubUsers}/> : null
                    }
                </Col>
            </Row>
            <hr/>
            <ClubCalendar user={user}/>
            <hr/>
            {
                showAdd2Management ?
                    <ClubAddBusinessAccount user={user} club={club}/> : null
            }
        </Grid>)
};
ClubDetails.propTypes = {
    club:PropTypes.object.isRequired,
    user:PropTypes.object,
    clubUsers: PropTypes.array,
    ownClubUser:PropTypes.object
};
const ClubHeader = ({club}) => {
    const url = club.getBannerImageUrl();
    if (url && url.length > 0) {
        return (
            <React.Fragment>
                <Image responsive className="center-block" src={url} /><br/>
            </React.Fragment>
        )
    }
    return (<PageHeader>{club.getName()}</PageHeader>)
};
ClubHeader.propTypes = {
    club:PropTypes.object.isRequired
};
const ClubButtons = ({user, club, ownClubUser}) => {
    function handleJoinButton() {
        if (user != null) {
            const reqClubUser = ClubUser.prototype.buildClubUserRequest(user, club);
            clubActions.joinClubUser(reqClubUser);
        } else {
            userActions.showLoginModal();
        }
    }
    if (ownClubUser == null ) {
        if (club.showJoinClubButton()) {
            return (<Button bsStyle="primary" onClick={handleJoinButton}>
                <FontAwesome icon="sign-in-alt"/><I18n code="club.button.join"/>
            </Button>);
        } else {
            return null;
        }
    } else {
        let bsStyle = "success";
        let role = ownClubUser.getRole();
        let roleStatus = null;
        let reqStati = ownClubUser.getReqStatus();
        if (reqStati != ClubUser.prototype.reqStati.accepted) {
            bsStyle = "warning";
            roleStatus = " - " + messages.get("cl_req_status." + reqStati);
        } else if (ownClubUser.isExMember()) {
            bsStyle = "default"
        }
        return (<React.Fragment>
            <h3><Label bsStyle={bsStyle}>{messages.get(role)}{roleStatus}</Label></h3>
            <ButtonGroup vertical block>
                {
                    ownClubUser.hasClubOwner() ? <ClubDataEditor user={user} club={club} /> : null
                }
                {
                    ownClubUser.hasUserHandling() ?
                        <Button href={linkUtil.getLink("club/"+ club.id + "/members")} bsStyle="primary" >
                            <FontAwesome icon="address-book"/><I18n code="cl_role_user"/>
                        </Button> : null
                }
                {
                    ownClubUser.hasInvoice() ?
                        <Button href={linkUtil.getLink("club/"+ club.id + "/accounting")} bsStyle="primary" >
                            <FontAwesome icon="calculator"/><I18n code="cl_role_invoice"/>
                        </Button> : null
                }
            </ButtonGroup>
        </React.Fragment>)
    }
};
ClubButtons.propTypes = {
    club:PropTypes.object.isRequired,
    user:PropTypes.object,
    ownClubUser:PropTypes.object
};
class ClubDataEditor extends React.Component {
    constructor(props) {
        super(props);
        this.openClubCreateModal = this.openClubCreateModal.bind(this);
        this.refClubCreateModal = React.createRef();
    }
    openClubCreateModal() {
        if (this.props.user !== null) {
            this.refClubCreateModal.current.edit(this.props.club);
        }
    }
    render() {
        const {user} = this.props;
        return <React.Fragment>
            <Button bsStyle="primary" onClick={this.openClubCreateModal}>
                <FontAwesome icon="pencil-alt"/><I18n code="club.edit.title"/>
            </Button>
            <ClubCreateModal ref={ this.refClubCreateModal} user={user}/>
        </React.Fragment>
    }
}
ClubDataEditor.propTypes = {
    club:PropTypes.object.isRequired,
    user:PropTypes.object
};
module.exports = ClubDetailsPage;
