const React = require("react"),
    PropTypes = require('prop-types');
const {Label, ButtonGroup, Button} = require("react-bootstrap");
const timeoutLockFactory = require("util/TimeoutLockFactory");
const {Range, getTrackBackground } = require('react-range');
// https://github.com/tajo/react-range#readme

const thumpLabelStyle = {position: 'absolute', top: '-18px'}
const thumpButtonStyle = {height: '42px', width: '42px', borderRadius: '4px', backgroundColor: '#FFF',
    display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 2px 6px #AAA'}
const thumpMarkerStyle = {height: '16px', width: '5px', backgroundColor: '#337ab7'}
const trackBoxStyle = {height: '36px', display: 'flex', width: '100%'}
const trackLineStyle ={height: '5px', width: '100%', borderRadius: '4px', alignSelf: 'center'}

class NumberRangeInput extends React.Component {
    constructor(props) {
        super(props);
        this.timeoutLock = timeoutLockFactory.createLock(1000);
        this.onChange = this.onChange.bind(this);
        this.renderTrack = this.renderTrack.bind(this);
        this.renderThump = this.renderThump.bind(this);
        this.setValue = this.setValue.bind(this);
        this.getValue = this.getValue.bind(this);

        let defValue;
        if (props.markerAmount === 1 && props.defaultValue != null) {
            defValue = [props.defaultValue];
        } else {
            let {min, max} = props;
            if  (props.defaultValue != null && props.defaultValue.length === 2) {
                min = props.defaultValue[0]
                max = props.defaultValue[1]
            }
            defValue = [min, max]
        }
        this.state = {
            values: defValue
        };
    }
    getValue() {
        return this.state.values;
    }
    setValue(value) {
        this.setState({
            values: value
        });
    }
    onChange(value) {
        this.setValue(value)
        if (this.props.onAfterChange != null) {
            this.timeoutLock.executeDelayed(
                _=>this.props.onAfterChange(value)
            );
        }
    }
    renderTrack(props, children, values) {
        const rtl = false
        const { min, max} = this.props
        return (<div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{...props.style, ...trackBoxStyle}}>
            <div ref={props.ref}
                style={{...trackLineStyle,
                    background: getTrackBackground({
                        values,
                        colors: ['#ccc', '#337ab7', '#ccc'],
                        min: min,
                        max: max,
                        rtl
                    })
                }}>
                {children}
            </div>
        </div>)
    }
    renderThump(index, props, isDragged) {
        const {values} = this.state;
        return (<div {...props} style={{...props.style, ...thumpButtonStyle}}>
            <Label bsStyle="primary" style={thumpLabelStyle}>{values[index].toFixed(1)}</Label>
            <div style={thumpMarkerStyle}/>
        </div>)
    }
    render() {
        const {step, min, max, label, defRangeGroups} = this.props
        const {values} = this.state;
        return <React.Fragment>
            {label}
            {
                defRangeGroups != null ? <ButtonGroup>
                    {
                        defRangeGroups.map(rangeGroup => {
                            let text =  rangeGroup[0] + "-" + rangeGroup[1]
                            return <Button key={"BtnRange_" + text} bsSize="small"
                                           onClick={() => this.onChange(rangeGroup)}>{text}</Button>
                        })
                    }
                    <br/><br/>
                </ButtonGroup> : null
            }
            <Range
                step={step}
                min={min}
                max={max}
                values={values}
                onChange={(values) => this.onChange(values)}
                renderTrack={({ props, children }) => this.renderTrack(props, children, values)}
                renderThumb={({ index, props, isDragged }) => this.renderThump(index, props, isDragged)}
            />
        </React.Fragment>
    }
}
NumberRangeInput.propTypes = {
    step: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    markerAmount: PropTypes.number.isRequired,
    onAfterChange:PropTypes.func,
    label:PropTypes.object,
    defaultValue:PropTypes.array,
    defRangeGroups: PropTypes.array,
    disabled:PropTypes.bool
};
module.exports = NumberRangeInput;
