const React = require("react");
const {BaseContext} = require("context/BaseContext");
const {User} = require("parse/_Domain");
const UserFeatures = require("beans/UserFeatures");

const notificationActions = require("components/notification/NotificationActions");
const userDao = require("actdao/UserDao");
const userActions = require("actions/UserActions"),
    languageActions = require("actions/LanguageActions");

const cookieUtil = require("util/cookieUtil");
const supportedLanguages = ['en', 'de']

const BaseContextProvider = ({children}) =>  {
    let defaultLanguage = "de";
    let defaultType = "A";
    var cookie = cookieUtil.read();
    if (cookie.language) {
        defaultLanguage = cookie.language;
    }
    if (cookie.imageDeviceType) {
        defaultType = cookie.imageDeviceType
    }

    const [user, setUser] = React.useState(null);
    const [userInfoState, setStateUserInfo] = React.useState({
        userDetail: null,
        userAbos: null,
        userFeatures: new UserFeatures(),
        userLoaded: false
    })
    const [languageState, setStateLanguage] = React.useState({
        language: defaultLanguage,
        supportedLanguages: supportedLanguages,
        imageDirPrefix: "",
        imageDeviceType: defaultType
    });
    const [tournamentMenuId, setTournamentIdForMenu] = React.useState(null);
    // const [userIsAdmin, setUserAdmin] = React.useState(null);
    function updateLanguage(urlLanguage) {
        if (languageState.supportedLanguages.indexOf(urlLanguage) > -1) {
            // only handle validated language
            cookieUtil.update("language", urlLanguage);
            setStateLanguage({
                language: urlLanguage,
                supportedLanguages: supportedLanguages,
                imageDirPrefix: urlLanguage !== "de" ? "/" + urlLanguage : "",
                imageDeviceType: defaultType
            })
            languageActions.switchLanguage(urlLanguage);
        }
    }
    function updateImageDeviceType(type) {
        cookieUtil.update("imageDeviceType", type);
        defaultType = type;
        setStateLanguage(prevState => ({
            ...prevState,
            imageDeviceType: type
        }))
    }
    function updateUser(user) {
        // console.log("Provider.setUser", user)
        setUser(user)
        fetchUserInfos(user).then()
        // TODO handle Abo pause
        // Todo handle change any of user or userDetail
        userActions.updateUser(user);
        // TODO remove legacy user by dropping userStore
    }
    async function fetchUserInfos(user) {
        let fUserAbos = null;
        let fUserDetail = null;
        if (user && user.get(User.prototype.col.userAbos)) {
            try {
                fUserAbos = await userDao.fetchUserAbos(user)
            } catch (error) {
                error.trace = "USRA.fetchUserAbo";
                notificationActions.parseError(error);
            }
        }
        if (user && user.get(User.prototype.col.userDetailID)) {
            try {
                fUserDetail = await userDao.fetchUserDetail(user)
            } catch (error) {
                error.trace = "USRA.fetchUserDetail";
                notificationActions.parseError(error);
            }

        }
        let nFeat = new UserFeatures();
        nFeat.setUserFeatures(fUserAbos);
        setStateUserInfo({
            userDetail: fUserDetail,
            userAbos: fUserAbos,
            userFeatures: nFeat,
            userLoaded: true
        })
        userActions.updateUserInfos(fUserAbos, fUserDetail);
    }
    return <BaseContext.Provider value={{ user, userInfoState, updateUser,
        tournamentMenuId, setTournamentIdForMenu,
        languageState, updateLanguage, updateImageDeviceType}}>
        {children}
    </BaseContext.Provider>
};
module.exports = BaseContextProvider;