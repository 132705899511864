/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {useMapsLibrary} = require("@vis.gl/react-google-maps");
const BaseMap = require("components/maps/BaseMap");
const TargetTrackLine = require("components/maps/TargetTrackLine");
const MapUtil = require("components/map/MapUtil");
const BasicMapMarker = require("components/maps/BasicMapMarker");
const ErrorBoundary = require("components/widgets/ErrorBoundary");


const MapEditableFrame = ({mapID, searchObj, mapCoordinates, eventTrack, targetIndex}) => {
    const hasMapCoordinates = mapCoordinates && mapCoordinates.length > 0 && mapCoordinates[0].coord != null;
    let mapUtil = new MapUtil();
    let center = {lat: 48.249863, lng: 14.635051}
    if (hasMapCoordinates) {
        let parcoursPosition = mapUtil.getCoordPosition(mapCoordinates[0])
        center = {lat: parcoursPosition.latitude, lng: parcoursPosition.longitude}
    }
    let trackElements = null
    if (eventTrack != null && eventTrack.getTrack() != null && targetIndex > 0) {
        trackElements = mapUtil.buildTrackObject(eventTrack.getTrack(), null, [], null, targetIndex)
    }
    return (
        <BaseMap mapOptions={{ center: center, mapID:mapID,
                        containerStyle: {width: "100%", height: '300px'},
                        zoom: 17, mapTypeId:"satellite"
                    }} elements={null}
                 geoSearchString={searchObj ? searchObj.searchString : null}
                 geoCallback={searchObj ? searchObj.dragEnd : null}>
            {
                hasMapCoordinates ? mapCoordinates.map(mapCoordinate => {
                    return <BasicMapMarker key={"BMP_"}
                        coordinate={mapUtil.getCoordPosition(mapCoordinate)} icon={mapCoordinate.iconUrl}
                        dragEndCallback={mapCoordinate.dragEnd}/>
                })  : null
            }
            {
                trackElements != null ? <MapsLibWrapper trackElements={trackElements} eventTrack={eventTrack}/> : null
            }
        }
        </BaseMap>
    )
};

const MapsLibWrapper = ({trackElements, eventTrack}) => {
    const maps = useMapsLibrary("maps");
    if (!maps) {
        return null;
    }
    return <React.Fragment>
        {
            trackElements.map(trackElement => {
                return <ErrorBoundary key={eventTrack.id + trackElement.targetNumber} identifier={"Target:" + trackElement.targetNumber}>
                    <TargetTrackLine coordArray={trackElement.coordArray} maps={maps}
                                     targetName={trackElement.targetName}
                                     targetPoints={trackElement.targetPoints}
                                     targetNumber={trackElement.targetNumber}
                                     posMarker={trackElement.posMarker}/>
                </ErrorBoundary>
            })
        }</React.Fragment>
}

MapEditableFrame.propTypes = {
    mapID: PropTypes.string.isRequired,
    searchObj: PropTypes.object,
    mapCoordinates: PropTypes.array.isRequired,
    eventTrack: PropTypes.object,
    targetIndex: PropTypes.number
};
module.exports = MapEditableFrame;