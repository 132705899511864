const React = require("react"),
    PropTypes = require('prop-types');

const {InfoWindow} = require('@vis.gl/react-google-maps');

const MapUtil = require("components/map/MapUtil");
const mapUtil = new MapUtil();

const MapMarkerContent = ({anchor, onClose, mapElement}) => {
    return <InfoWindow anchor={anchor} onClose={onClose}
                       headerContent={<h4><a href={mapElement.mainLink} target='_blank'>{mapElement.name}</a></h4>}>
        <div dangerouslySetInnerHTML={{__html:mapUtil.generateMarkerContent(mapElement, true)}}/>
    </InfoWindow>
}
MapMarkerContent.propTypes = {
    mapElement: PropTypes.object.isRequired,
    anchor: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
module.exports = MapMarkerContent;