const alt = require("AltInit"),
    linkUtil = require("linkUtil"),
    messages = require("i18n/messages"),
    {ClubUser, User, ParcoursArticle, Address} = require("parse/_Domain");

const parseDao = require("actdao/ParseBaseDao");
const clubDao = require("actdao/ClubDao");
const parcoursDao = require("actdao/ParcoursDao");
const userDao = require("actdao/UserDao");
const managementDao = require("actdao/ManagementDao");
const notificationActions = require("components/notification/NotificationActions");
const msgActions = require("actions/MessageActions");
const Utility = require("util/Utility");
const ParseListUtility = require("util/ParseListUtility");

class ClubActions {

    loadClub(id, user, neededClubUserRole, clubUserId){
        clubDao
            .loadClubDetails(id)
            .then(pClubDetail => {
                // reset search params
                this.updateSearchParams({searchText: "", sortParams: {}});
                this.loadClubUser(pClubDetail, user, neededClubUserRole, clubUserId);
                this.updateClubDetail(pClubDetail);
            })
            .catch(error => {
                error.trace = "CLBA.loadClub";
                notificationActions.parseError(error);
            });
        return {};
    }

    updatePaymentTarget(editPaymentTarget, club) {
        if (club.getPaymentTargetID() == null) {
            club.setPaymentTargetID(editPaymentTarget);
            this.saveClub(club);
        } else {
            parseDao.saveObject(editPaymentTarget)
                .then(sPaymentTarget => {
                    club.setPaymentTargetID(sPaymentTarget);
                    this.updateClubDetail(club);
                })
                .catch(error => {
                    error.trace = "CLBA.sPayTarget";
                    notificationActions.parseError(error);
                });
        }
        return {};
    }

    // sets loading to true for club list
    showProgress() {
        return {};
    };

    searchOwnClubs(searchParams, user) {
        clubDao.searchOwnClubs(user)
            .then(clubUsers => {
                let clubs = [];
                clubUsers.map(clubUser => {
                    if (clubUser.getClubID() != null) {
                        ParseListUtility.updateOrPush(clubs, clubUser.getClubID())
                    }
                })
                if (clubs.length > 0) {
                    // show own clubs only
                    this.updateClubsList(clubs);
                } else {
                    // no own clubs - show all
                    this.searchClubs(searchParams)
                }
            })
            .catch(error => {
                error.trace = "CLBA.searchOwnClubs";
                notificationActions.parseError(error);
            });
        return {};
    }

    searchClubs(searchParams) {
        clubDao
            .searchClubs(searchParams)
            .then(clubs => {
                this.updateClubsList(clubs);
            })
            .catch(error => {
                error.trace = "CLBA.searchClubs";
                notificationActions.parseError(error);
            });
        return {};
    }

    searchClubUser(searchParams) {
        return searchParams;
    }

    updateSearchParams(searchParams) {
        return searchParams;
    }

    loadClubUser(pClubDetail, user, neededClubUserRole, clubUserId) {
        clubDao.loadClubUserList(pClubDetail, user)
            .then(ownClubUsers => {
                let ownClubUser = this.getOwnClubUser(ownClubUsers, user);
                if (ownClubUser && neededClubUserRole) {
                    if (ownClubUser.hasFunction(neededClubUserRole)) {
                        // user has role
                        this.updateOwnClubUser(ownClubUser);
                        this.loadClubUserList(pClubDetail, clubUserId)
                    } else {
                        // not member of the club or missing the needed role
                        this.setInvalidUser();
                    }
                } else if (neededClubUserRole != null && ownClubUser == null) {
                    // not member of the club for missing the needed role
                    this.setInvalidUser();
                } else {
                    this.updateOwnClubUser(ownClubUser);
                    this.loadClubUserList(pClubDetail, null)
                }
            })
            .catch(error => {
                error.trace = "CLBA.loadClubUser.loadClubUserList";
                notificationActions.parseError(error);
            });
        return {};
    }

    loadClubUserList(pClubDetail, clubUserId) {
        clubDao.loadClubUserList(pClubDetail, null)
            .then(clubUsers => {
                this.updateClubUsersList(clubUsers);
                if (clubUserId != null) {
                    this.handleEditClubUser(clubUsers, clubUserId);
                }
            })
            .catch(error => {
                error.trace = "CLBA.loadClubDetails.loadClubUserList";
                notificationActions.parseError(error);
            });
        return {}
    }

    handleEditClubUser(clubUsers, clubUserId) {
        let editClubUser = this.getClubUser(clubUsers, clubUserId);
        if (editClubUser && editClubUser.getUserID()) {
            this.setEditClubUser(editClubUser);
            // clubUser has user - check club User userDetail
            let cuUserDetail = editClubUser.getUserDetailID();
            let uUserDetail = editClubUser.getUserID().get("userDetailID");
            // validate
            if (uUserDetail != null && cuUserDetail ==  null) {
                // user has detail but clubUser not - update club User
                editClubUser.setUserDetailID(uUserDetail);
                parseDao.saveObject(editClubUser)
                    .then(sEditClubUser => {
                        // updated user reload page
                        location.reload();
                    })
                    .catch(error => {
                        error.trace = "CLBA.loadClubDetails.updateClubUserDetails";
                        notificationActions.parseError(error);
                    });
            } else if(cuUserDetail != null && uUserDetail ==  null) {
                // clubUser has detail but user not
                const params = {userID: editClubUser.getUserID().id, userDetailID: cuUserDetail.id, updUserDetailACL: true};
                userDao.editUserCCP(params)
                    .then(result => {
                        // updated user reload page
                        location.reload();
                    })
                    .catch(error => {
                        error.trace = "CLBA.loadClubDetails.updateUserDetails";
                        notificationActions.parseError(error);
                    });
            } else if (uUserDetail != null && cuUserDetail != null && uUserDetail.id != cuUserDetail.id) {
                notificationActions.info("Zwei unterschiedliche Adressen von Benutzer und Vereinsmitglied. Dieser Konflikt kann nicht automatisch aufgelöst werden - bitte bei 3D SKill Board melden und CLUBUSERID: " + editClubUser.id + " angeben.")
            }
        } else {
            // no club user or normal user - no checks needed
            this.setEditClubUser(editClubUser);
        }
        return {}
    }

    loadClubEvents(club, ownClubUser, searchParams) {
        this.updateEventLoading(true);
        clubDao.loadClubEvents(club,ownClubUser, searchParams)
            .then(clubEvents => {
                this.updateClubEventsList(clubEvents.filter(function (clubEv) {
                    return clubEv.checkVisibilityGroup(ownClubUser);
                }));
            })
            .catch(error => {
                error.trace = "CLBA.loadClubEvents";
                notificationActions.parseError(error);
            });
        return {};
    }
    updateEventLoading(loading) {
        return loading;
    };

    getOwnClubUser(clubUsers, user) {
        if (user != null && clubUsers != null) {
            let length = clubUsers.length;
            for (let i = 0; i<length; i++) {
                let clUUser = clubUsers[i].getUserID();
                if (clUUser != null && clUUser.id === user.id) {
                    return clubUsers[i];
                }
            }
        }
        return null;
    }

    getClubUser(clubUsers, clubUserId) {
        if (clubUserId != null && clubUsers != null) {
            let length = clubUsers.length;
            for (let i = 0; i<length; i++) {
                if (clubUsers[i].id == clubUserId) {
                    return clubUsers[i];
                }
            }
        }
        return null;
    }

    createOrEditClubArticle(club, editArticle, values) {
        let article = editArticle;
        if (article == null) {
            article = new ParcoursArticle();
            article.setStatus(ParcoursArticle.prototype.status.active);
        }
        article.setName(values.name);
        article.setPrice(values.price);
        article.setCurrency(values.currency);
        article.setGroup(values.group);
        // store article
        parseDao.saveObject(article)
            .then(sArticle => {
                if (editArticle == null) {
                    // is new article - add to club
                    let articles = club.getArticles();
                    if (articles == null) {
                        articles = [];
                    }
                    articles.push(sArticle);
                    club.setArticles(articles);
                    this.saveClub(club);
                }
            })
            .catch(error => {
                error.trace = "CLBA.creArt";
                notificationActions.parseError(error);
            });
        return {};
    }

    deleteClubArticle(club, article) {
        article.setStatus(ParcoursArticle.prototype.status.deleted);
        parseDao.saveObject(article)
            .then(sArticle => {
                let newArticles = [];
                if (club.getArticles()) {
                    club.getArticles().forEach(existArticle => {
                        if (existArticle.id !== sArticle.id) {
                            newArticles.push(existArticle);
                        }
                    });
                }
                club.setArticles(newArticles);
                this.saveClub(club);
            })
            .catch(error => {
                error.trace = "CLBA.delArt";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateArticleStatus(club, article, status) {
        article.setStatus(status);
        parseDao.saveObject(article)
            .then(sArticle => {
                // needed to rerender page
                this.updateClubDetail(club)
            })
            .catch(error => {
                error.trace = "CLBA.updArtStat";
                notificationActions.parseError(error);
            });
        return {};
    }


    updateClubDetail(pClubDetail) {
        return pClubDetail;
    }

    updateClubsList(pClubList) {
        return pClubList;
    }

    updateClubUsersList(pClubUserList) {
        return pClubUserList;
    }

    updateClubEventsList(pClubEventList) {
        return pClubEventList;
    }

    updateClubEvent(clubEvent) {
        return clubEvent;
    }

    removeClubEvent(clubEvent) {
        return clubEvent;
    }

    updateOwnClubUser(pClubUser) {
        return pClubUser;
    }

    addClubUser(clubUser) {
        return clubUser;
    }

    createClub(club, clubUser, parcoursOwner) {
        parseDao.saveObject(club)
            .then(sClub => {
                if (sClub.getManagementID() != null) {
                    managementDao.requestClubManagement(sClub.getManagementID(), club)
                        .then(sManagement => {
                             console.log("created request");
                            })
                        .catch(error => {
                            console.log("Could not create club request");
                            console.dir(error);
                        })
                }
                this.setClubUserRole(clubUser.getUserID());
                this.updateClubUserFavorite(club, true, clubUser.getUserID());
                clubUser.setClubID(sClub);
                parseDao.saveObject(clubUser)
                    .then(sClubUser => {
                        if (parcoursOwner != null) {
                            // cloud code change of parcoursOwner
                            this.updateParcoursCC(parcoursOwner, "clubID", sClub.id, () => {
                                window.location.href = linkUtil.getLink("club/" + sClub.id);
                            });
                        } else {
                            window.location.href = linkUtil.getLink("club/" + sClub.id);
                        }
                    })
                    .catch(error => {
                        error.trace = "CLBA.createOwnerClubUser";
                        notificationActions.parseError(error);
                    });
            })
            .catch(error => {
                error.trace = "CLBA.createClub";
                notificationActions.parseError(error);
            });
        return {};
    };

    resendClubAuthenticationMail(club) {
        userDao.resendClubAuthenticationMail(club, club.getEmail())
            .then(result => {
                notificationActions.success("mail.sent.success");
            })
            .catch(error => {
                error.trace="CLBA.rsClubAuthMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    setVerifiedEmailFlag(club, callback) {
        club.setEmailVerified(true);
        parseDao.saveObject(club)
            .then(sClub => {
                callback("E-Mail verified")
            })
            .catch(error => {
                error.trace="CLBA.vClubMail";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateClubGroups(club, addType, removeType) {
        if (addType != null) {
            club.addGroup(addType);
        }
        if (removeType != null) {
            club.removeGroup(removeType);
        }
        this.saveClub(club);
        return {};
    };

    link2BusinessAccount(club, management) {
        club.setManagementID(management);
        const addEditor = [];
        const mainEditorID = management.getMainEditor().id;
        if (club.getMainEditor().id != mainEditorID) {
            // other mainEditor - push to addEditor list
            addEditor.push(club.getMainEditor());
            club.setMainEditor(club.getMainEditor());
        }
        // existing unique
        if (club.getAddEditor() != null) {
            club.getAddEditor().map(function(editor) {
                if (editor.id != mainEditorID) {
                    ParseListUtility.updateOrPush(addEditor, editor);
                }
            });
        }
        if (management.getAddEditor() != null) {
            management.getAddEditor().map(function(editor) {
                if (editor.id != mainEditorID) {
                    ParseListUtility.updateOrPush(addEditor, editor);
                }
            });
        }
        club.setAddEditor(addEditor);
        this.saveClub(club);
        // TODO add club also to management
        return {};
    }

    saveClub(club) {
        parseDao.saveObject(club)
            .then(sClub => {
               this.updateClubDetail(sClub);
            })
            .catch(error => {
                error.trace = "CLBA.saveClub";
                notificationActions.parseError(error);
            });
        return {};
    };
    saveClubCB(club, callback) {
        parseDao.saveObject(club)
            .then(sClub => {
                if (callback) {
                    callback()
                }
                this.updateClubDetail(sClub);
            })
            .catch(error => {
                error.trace = "CLBA.saveClubCB";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveClubUser(clubUser) {
        parseDao.saveObject(clubUser)
            .then(sClub => {
                this.updateClubUser(clubUser);
            })
            .catch(error => {
                error.trace = "CLBA.saveClubUser";
                notificationActions.parseError(error);
            });
        return {};
    };

    createClubUserAddress(clubUser, usrDetail, address) {
        var cuExisted = clubUser.existed();
        parseDao.saveObject(address)
            .then(sAddress => {
                usrDetail.setAddressID(sAddress);
                parseDao.saveObject(usrDetail)
                    .then(sUsrDetail => {
                        clubUser.setUserDetailID(sUsrDetail);
                        parseDao.saveObject(clubUser)
                            .then(sClubUser => {
                                // update also user if existing and has no userDetail yet
                                if (clubUser.getUserID() != null && clubUser.getUserID().get("userDetailID") == null) {
                                    const params = {userID: clubUser.getUserID().id, userDetailID: sUsrDetail.id};
                                    userDao.editUserCCP(params)
                                }
                                if (cuExisted) {
                                    this.updateClubUser(sClubUser);
                                } else {
                                    this.addClubUser(sClubUser);
                                }

                            });
                    });
            })
            .catch(error => {
                error.trace = "CLBA.createClubUserAddress";
                notificationActions.parseError(error);
            });
        return {};
    }

    createAddressTwo(clubUser, user) {
        const address = new Address();
        address.setStatus(Address.prototype.status.A);
        address.buildACL(user, "club", null);
        clubUser.setAddressTwoID(address);
        this.saveClubUser(clubUser);
    }

    updateClubUserAddress(clubUser, usrDetail, address) {
        if (usrDetail.dirty() || address.dirty()) {
            clubDao.updateClubUserAddress(usrDetail, address)
                .then(sUsrDetail => {
                    clubUser.setUserDetailID(sUsrDetail);
                    this.updateClubUser(clubUser);
                })
                .catch(error => {
                    error.trace = "CLBA.updateClubUserAddress";
                    notificationActions.parseError(error);
                });
        }
        return {};
    };

    setClubUserRole(user) {
        if (user != null) {
            userDao.setClubUserRole(user)
                .then(rUser => {
                    console.log("Club role success");
                })
                .catch(error => {
                    console.log("Could not add club role");
                    console.dir(error);
                });
        }
        return {};
    }

    joinClubUser(clubUser) {
        parseDao.saveObject(clubUser)
            .then(clubUser => {
                this.sentRegistrationRequestEmail(clubUser, clubUser.getClubID());
                this.updateOwnClubUser(clubUser);
                this.addClubUser(clubUser);
            })
            .catch(error => {
                error.trace = "CLBA.joinClubUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    removeClubGroupFromClubUsers(clubUsers, group) {
        let groupClubUser = clubUsers.filter(cu => {
            if (cu.hasGroup(group)) {
                cu.removeGroup(group);
                return true;
            }
            return false;
        });
        if (groupClubUser != null && groupClubUser.length > 0) {
            parseDao.saveAll(groupClubUser)
                .then(sClubUsers => {
                    sClubUsers.map(sClubUser => {
                        this.updateClubUser(sClubUser);
                    })
                })
                .catch(error => {
                    error.trace = "CLBA.remGroupClubUser";
                    notificationActions.parseError(error);
                });
        }
        return {};
    }

    deleteClubUser(club, clubUser) {
        parseDao.deleteObject(clubUser)
            .then(clubUser => {
                this.deleteClubUserDetails(clubUser);
                this.deleteClubUserPaymentTarget(clubUser.getPaymentTargetID());
                this.updateClubUserFavorite(club, false, clubUser.getUserID());
                this.removeClubUser(clubUser);
                setTimeout(function () {
                    location.reload();
                }, 1000);
            })
            .catch(error => {
                error.trace = "CLBA.deleteClubUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    deleteClubUserDetails(clubUser) {
        if (clubUser.getUserID() && clubUser.getUserID().getUserDetailID() && clubUser.getUserDetailID()
            && clubUser.getUserID().getUserDetailID().id == clubUser.getUserDetailID().id) {
            // user.userDetail is the same as clubUser.userDetail -> must not delete userDetail!
        } else if (clubUser.getUserDetailID()) {
            // has userDetail which is different to user or user is null -> delete UserDetail && Address && UserLics
            clubDao.deleteUserDetail(clubUser.getUserDetailID())
                .then(success=> {

                })
                .catch(error => {
                    error.trace = "CLBA.deleteClubUserDetail";
                    notificationActions.parseError(error);
                });
        }
        return {};
    }

    deleteClubUserPaymentTarget(paymentTarget) {
        if (paymentTarget) {
            paymentTarget.setStatus("D");
            parseDao.saveObject(paymentTarget)
                .then(sPaymentTarget => {
                })
                .catch(error => {
                    error.trace = "TRAA.delPayTarg";
                    notificationActions.parseError(error);
                });
        }
        return {};
    }

    updateClubUserFavorite(club, add2Club, user) {
        if (user != null) {
            clubDao.updateClubUserFavorite(club, add2Club, user)
                .then(result => {
                    console.log("update success club favorite " + result);
                    //this.fetchUserFavorite(user);
                })
                .catch(error => {
                    error.trace = "CLBA.updateClubUserFavorite";
                    notificationActions.parseError(error);
                });
        }
        return {};
    };

    declineClubUser(clubUser, club) {
        clubUser.setReqStatus(ClubUser.prototype.reqStati.declined);
        parseDao.saveObject(clubUser)
            .then(clubUser => {
                this.updateClubUserFavorite(club, false, clubUser.getUserID());
                this.sentRequestAnswerEmail(clubUser, clubUser.getClubID());
                this.removeClubUser(clubUser);
            })
            .catch(error => {
                error.trace = "CLBA.declineClubUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    acceptClubUser(clubUser, club) {
        clubUser.setReqStatus(ClubUser.prototype.reqStati.accepted);
        parseDao.saveObject(clubUser)
            .then(clubUser => {
                this.updateClubUserFavorite(club, true, clubUser.getUserID());
                this.sentRequestAnswerEmail(clubUser, club);
                this.updateClubUser(clubUser);
            })
            .catch(error => {
                error.trace = "CLBA.acceptClubUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateParcoursCC(object, dirtyKey, newValue, then) {
        parcoursDao.parseEditParcoursCC(object, dirtyKey, newValue)
            .then(savedObject => {
                if (then) {
                    then();
                }
            })
            .catch(error => {
                error.trace = "CLBA.updateParcoursCC";
                notificationActions.parseError(error);
                if (then) {
                    then();
                }
            });
        return {};
    }

    saveClubEvent(clubEvent, mailAddresses) {
        parseDao.saveObject(clubEvent)
            .then(clubEvent => {
                this.sentEmailClubEvent(clubEvent, mailAddresses);
                this.updateClubEvent(clubEvent);
            })
            .catch(error => {
                error.trace = "CLBA.saveClubEvent";
                notificationActions.parseError(error);
            });
        return {};
    }

    handleClubEventOption(clubEvent, ownClubUser, option) {
        clubDao.updateClubEventOption(clubEvent, ownClubUser, option)
            .then (uClubEvent => {
                this.updateClubEvent(uClubEvent);
            })
            .catch(error => {
                error.trace = "CLBA.handleClubEventOption";
                notificationActions.parseError(error);
            });
        return {};
    }

    deleteEvent(clubEvent) {
        parseDao.deleteObject(clubEvent)
            .then(clubEvent => {
                this.removeClubEvent(clubEvent);
            })
            .catch(error => {
                error.trace = "CLBA.deleteEvent";
                notificationActions.parseError(error);
            });
    }

    updateClubInvoiceType(club, addType, removeType) {
        if (addType != null) {
            club.addInvoiceType(addType);
        }
        if (removeType != null) {
            club.removeInvoiceType(removeType);
        }
        parseDao.saveObject(club)
            .then(sClub => {
                this.updateClubDetail(sClub);
            })
            .catch(error => {
                error.trace = "CLBA.saveClubInvType";
                notificationActions.parseError(error);
            });
        return {};
    };

    sentPushMessage(club, mailsArray, message) {
        var clubLink = linkUtil.getFullLink('/club/' + club.id);
        msgActions.sentPushMessageToClub(club.id, mailsArray, club.getName(), message, clubLink, msgActions.pushCallbackNotification);
        return {};
    }
    sentEmailClubEvent(clubEvent, mailsArray) {
        if (mailsArray == null) {
            return {};
        }
        const club = clubEvent.getClubID();
        const subject = club.getName() + " - " + clubEvent.getName();
        const sender = club.getEmail();
        let clubEventLink = linkUtil.getFullLink('/club/event/' + clubEvent.id);
        if (clubEvent.getLinkSecret() != null && clubEvent.getLinkSecret().length > 0) {
            // need to add secret to link
            clubEventLink += ("/" + clubEvent.getLinkSecret());
        }
        const calendarLink = linkUtil.getFullLink('/calender.html?ceID=' + clubEvent.id+ "&amp;calType=");
        // build message
        let htmlMessage = '<html><body>';
        htmlMessage += '<h1>'+subject+'</h1>';
        htmlMessage += '<p><strong>' + messages.get("tournament.create.time") + ': </strong>'
            + Utility.getReadableDate(clubEvent.getDate()) + " - " + Utility.getReadableTime(clubEvent.getDate()) + '</p>';
        htmlMessage += '<p><strong>' + messages.get("tournament.create.endtime") + ': </strong>'
            + Utility.getReadableDate(clubEvent.getEndDate()) + " - " + Utility.getReadableTime(clubEvent.getEndDate()) + '</p>';
        if (clubEvent.getDescription()) {
            htmlMessage += '<p>' + clubEvent.getDescription() + '</p>';
        }
        if (clubEvent.getWeblink()) {
            htmlMessage += '<p><a href="' + clubEvent.getWeblink() + '">' + clubEvent.getWeblink() + '</a></p>';
        }
        if (clubEvent.getDocument()) {
            htmlMessage += '<p><a href="' + clubEvent.getDocument().url() + '">' + clubEvent.getDocumentName() + '</a></p>';
        }
        if (clubEvent.getPlace()) {
            htmlMessage += '<p><strong>' + messages.get("club.event.create.place.title") + ': </strong>'
                + clubEvent.getPlace() + '</p>';
        }
        htmlMessage += '<p>' + messages.get("club.mail.ending") + '<br>' + club.getName() +  '</p>';
        htmlMessage += '<a href="' + clubEventLink + '">' + messages.get("club.mail.clubEventLink") + '</a><br><br>';
        htmlMessage += '<hr><h3>' + messages.get("tournament.register.mail.addCalendar") + ':</h3>';
        htmlMessage += '<a href="' + calendarLink + 'google">Google</a> - ';
        htmlMessage += '<a href="' + calendarLink + 'ical">iCal</a> - ';
        htmlMessage += '<a href="' + calendarLink + 'outlook">Outlook</a>';
        htmlMessage += '</body></html>';


        const mailsArrayJunks = Utility.chunkArray(mailsArray, 50);
        mailsArrayJunks.map(mailsArrayJunk => {
            let receiver = mailsArrayJunk.join(',');
            msgActions.sentMassEmail(subject, sender, receiver, htmlMessage, msgActions.mailCallbackNotification);
        });
        return {};
    }
    sentEmailMessage(club, mailsArray, message) {
        if (mailsArray == null) {
            return {};
        }
        const subject = club.getName();
        const sender = club.getEmail();
        const clubLink = linkUtil.getFullLink('/club/' + club.id);
        // build message
        let htmlMessage = '<html><body>';
        htmlMessage += '<h1>'+subject+'</h1>';
        htmlMessage += '<p>' + message.replace(/\n/g, "<br/>") + '</p>';
        htmlMessage += '<a href="' + clubLink + '">' + messages.get("club.mail.clubLink") + '</a>';
        htmlMessage += '</body></html>';

        const mailsArrayJunks = Utility.chunkArray(mailsArray, 50);
        mailsArrayJunks.map(mailsArrayJunk => {
            let receiver = mailsArrayJunk.join(',');
            msgActions.sentMassEmail(subject, sender, receiver, htmlMessage, msgActions.mailCallbackNotification);
        });
        return {};
    }

    sentRegistrationRequestEmail(clubUser, club) {
        var sender = clubUser.getUserID().get(User.prototype.col.email);
        var subject = club.getName();
        var receiver = club.getEmail();
        var clubLink = linkUtil.getFullLink('/club/' + club.id);
        // build message
        var htmlMessage = '<html><body>';
        htmlMessage += '<h1>'+subject+'</h1>';
        htmlMessage += '<h3>' + messages.get("club.mail.clubuser.request") + ':</h3>';
        htmlMessage += '<p><strong>' + messages.get("tournament.table.column.name") + ': </strong>' + clubUser.getFullName() + '</p>';
        htmlMessage += '<p><strong>' + messages.get("address.mail") + ': </strong>' + clubUser.getEmail() + '</p>';
        htmlMessage += '<a href="' + clubLink + '">' + messages.get("club.mail.clubLink") + '</a>';
        htmlMessage += '</body></html>';
        msgActions.sentEmail(subject, sender, receiver, null, htmlMessage, null);
        return {};
    }
    sentRequestAnswerEmail(clubUser, club) {
        // TODO - does not work as email can't be found
        var receiver = clubUser.getUserID().id;
        var subject = club.getName();
        var sender = club.getEmail();
        var clubLink = linkUtil.getFullLink('/club/' + club.id);
        // build message
        var htmlMessage = '<html><body>';
        htmlMessage += '<h1>'+subject+'</h1>';
        htmlMessage += '<h3>' + messages.get("club.mail.clubuser.request") + ": " + messages.get("cl_req_status." + clubUser.getReqStatus())+ '</h3>';
        htmlMessage += '<a href="' + clubLink + '">' + messages.get("club.mail.clubLink") + '</a>';
        htmlMessage += '</body></html>';
        msgActions.sentEmail(subject, sender, receiver, null, htmlMessage, null);
        return {};
    }

    sendInvitationMails(club, mails) {
        var receiver = mails;
        var subject = club.getName();
        var sender = club.getEmail();
        var clubLink = linkUtil.getFullLink('/club/' + club.id);
        // build message
        var htmlMessage = '<html><body>';
        htmlMessage += '<h1>'+subject+'</h1>';
        htmlMessage += '<p>' + messages.get("club.mail.invitation") + '<br>' + messages.get("club.need.account") + '</p>';
        htmlMessage += '<a href="' + clubLink + '">' + messages.get("club.mail.clubLink") + '</a>';
        htmlMessage += '</body></html>';
        msgActions.sentMassEmail(subject, sender, receiver, htmlMessage, msgActions.mailCallbackNotification);
        return {};
    }

    resetClubDetails() {
        return {};
    }
    updateClubUser(clubUser) {
        return clubUser;
    }
    removeClubUser(clubUser) {
        return clubUser;
    }
    setEditClubUser(clubUser) {
        return clubUser;
    }
    setInvalidUser() {
        console.log("INVALID USER ACTION");
        return{};
    };
}

module.exports = alt.createActions(ClubActions);
