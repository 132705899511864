const React = require("react"),
    PropTypes = require('prop-types');

const {InfoWindow} = require('@vis.gl/react-google-maps');
const messages = require("i18n/messages");
const MapUtil = require("components/map/MapUtil");
const mapUtil = new MapUtil();

const TargetMarkerContent = ({anchor, onClose, mapElement}) => {
    return <InfoWindow anchor={anchor} onClose={onClose}
                       headerContent={<h4>{messages.get("tournament.table.column.target")} {mapElement.getIndex()}</h4>}>
        <div dangerouslySetInnerHTML={{__html:mapUtil.generateTargetMarker(mapElement)}}/>
    </InfoWindow>
}
TargetMarkerContent.propTypes = {
    mapElement: PropTypes.object.isRequired,
    anchor: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
module.exports = TargetMarkerContent;