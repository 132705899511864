const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Table, PageHeader} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    CheckboxInput = require("components/form/CheckboxInput"),
    ParcoursLoader = require("components/parcours/ParcoursLoader"),
    TargetMapFrame = require("components/maps/TargetMapFrame"),
    ParcoursTargetButton = require("components/links/ParcoursTargetButton"),
    FontAwesome = require("components/widgets/FontAwesome"),
    MonthPagerBasic = require("components/widgets/MonthPagerBasic"),
    Loading = require("components/widgets/Loading");
const PLUtil = require("util/ParseListUtility");
const EventDetailLink = require("components/links/EventDetailLink");

const parcoursTargetActions = require("actions/ParcoursTargetActions"),
    parcoursTargetStore = require("stores/ParcoursTargetStore");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const ParcoursTargetTrackPage = ({onlineID, tstat}) =>{
     return <ParcoursLoader onlineID={onlineID} rating={false} editCheck={"T"} loginMandatory={true} tstat={tstat}
                            invalidUserDesc={<I18n code="parcours.invalidUser.desc"/>}>
        <ParcoursDetails />
    </ParcoursLoader>
};
ParcoursTargetTrackPage.propTypes = {
    onlineID: PropTypes.string.isRequired,
    tstat: PropTypes.string
};
class ParcoursDetails extends React.Component {
    constructor(props) {
        super(props);
        this.handleParcoursTargetChange = this.handleParcoursTargetChange.bind(this);
        this.state = {
            parcoursTargetStore: parcoursTargetStore.getState()
        }
    }
    componentDidMount() {
        document.title = this.props.parcours.buildNames();
        parcoursTargetStore.listen(this.handleParcoursTargetChange);
        parcoursTargetActions.loadParcoursTargets.defer(this.props.parcours.getOnlineID());
    }
    componentWillUnmount() {
        parcoursTargetStore.unlisten(this.handleParcoursTargetChange);
    }
    handleParcoursTargetChange(state) {
        this.setState({parcoursTargetStore: state});
    }
    render() {
        const {parcours, showEventLink} = this.props;
        const {parcoursTargetStore} = this.state;
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/parcours" code="header.nav.parcours"/>
                            <Breadcrumb link={"/parcours/" + parcours.getOnlineID() } code={parcours.buildNames()}/>
                            <Breadcrumb code="parcours.targets.header" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <PageHeader>{parcours.buildNames()} </PageHeader>
                    </Col>
                </Row>
                {
                    parcoursTargetStore.loading === true ?
                        <Row><Loading/></Row>
                        :
                        <Row>
                            {
                                parcours.hasTargetEnabled(true) != true ?
                                    <Col xs={12}><Alert bsStyle="info"><I18n code="parcours.ranking.noParcoursFeature"/></Alert></Col> : null
                            }
                            <Col xs={12}>
                                <TargetMapFrame targets={parcoursTargetStore.parcoursTargets}
                                                parcours={parcours}
                                                mapEventTracks={parcoursTargetStore.mapEventTracks}/>
                                <br/><br/>
                            </Col>
                            <EventTrack parcours={parcours} showEventLink={showEventLink}
                                        loading={parcoursTargetStore.loadingEventTracks}
                                        mapEventTracks={parcoursTargetStore.mapEventTracks}
                                        eventTracks={parcoursTargetStore.eventTracks}/>
                        </Row>

                }
            </Grid>)
    }
}
ParcoursDetails.propTypes = {
    parcours:PropTypes.object.isRequired,
    user:PropTypes.object,
    showEventLink: PropTypes.bool.isRequired
};
class EventTrack extends React.Component {
    constructor(props) {
        super(props);
        this.handleChangedDate = this.handleChangedDate.bind(this);
        const today = new Date();
        this.state = {
            month: today.getMonth() + 1,
            year: today.getFullYear()
        }
    }
    componentDidMount() {
        parcoursTargetActions.loadEventTracks.defer(this.props.parcours.getOnlineID(), this.state.month, this.state.year);
    }
    handleChangedDate(month, year, yearChanged) {
        this.setState({
            month: month,
            year: year
        });
        parcoursTargetActions.loadEventTracks(this.props.parcours.getOnlineID(), month, year);
    }
    render() {
        const {loading, parcours, mapEventTracks, eventTracks, showEventLink} = this.props;
        const { year, month} = this.state;
        return <React.Fragment>
            <Col xs={12}>
                <MonthPagerBasic year={year} month={month} headerText={messages.get("statistics.column.event.amount")}
                                 handleChangedDate={this.handleChangedDate}/>
            </Col>

            {
                loading  === true ? <Col xs={12}><Loading/></Col> :
                    <EventTrackList parcours={parcours} eventTracks={eventTracks} mapEventTracks={mapEventTracks} showEventLink={showEventLink}/>
            }
            </React.Fragment>
    }
}
EventTrack.propTypes = {
    parcours:PropTypes.object.isRequired,
    eventTracks:PropTypes.array.isRequired,
    mapEventTracks:PropTypes.array,
    loadingEventTracks: PropTypes.bool.isRequired,
    showEventLink: PropTypes.bool.isRequired
};
const EventTrackList = ({parcours, eventTracks, mapEventTracks, showEventLink}) =>{
    const isTrackShownInMap = (event) => {
        return PLUtil.contains(mapEventTracks, event.getTrackID());
    };
    return (<Col xs={12}>
            <Table fill>
            <thead>
            <tr>
                <th><I18n code="tournament.table.column.name"/></th>
                <th><I18n code="tournament.table.column.date"/></th>
                <th><FontAwesome icon="clock"/></th>
                <th><FontAwesome icon="walking"/></th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {
                eventTracks.length == 0 ? null :
                    eventTracks.map(event => {
                        let isShown = isTrackShownInMap(event);
                        return <EventTrackEntry key={event.id} parcours={parcours} event={event} isShownInMap={isShown} showEventLink={showEventLink}/>
                    })
            }
            </tbody>
        </Table>
        {
            eventTracks.length == 0 ?
                <Alert bsStyle="warning"><I18n code="profile.events.empty"/></Alert>
                : null
        }
    </Col>)
};
EventTrackList.propTypes = {
    parcours:PropTypes.object.isRequired,
    eventTracks:PropTypes.array.isRequired,
    mapEventTracks:PropTypes.array,
    showEventLink: PropTypes.bool.isRequired
};
const EventTrackEntry = ({parcours, event, isShownInMap, showEventLink}) => {
    const eventTrack = event.getTrackID();
    const handleCBClick = (value) => {
        parcoursTargetActions.updateMapEventTracks(value, eventTrack)
    };
    return (<tr>
        <td><CheckboxInput default={isShownInMap} onAfterChange={handleCBClick} message={event.getName()}/></td>
        <td>{event.getDate()}</td>
        <td>{eventTrack.getTrackDuration()}</td>
        <td>{eventTrack.getTrackDistance()}</td>
        <td><ParcoursTargetButton bsStyle="success" parcours={parcours} edit={true} eventTrack={eventTrack}/>{showEventLink === true ? <EventDetailLink eventID={event.id}/> : null}</td>
    </tr>)
};
EventTrackEntry.propTypes = {
    parcours:PropTypes.object.isRequired,
    event:PropTypes.object.isRequired,
    isShownInMap: PropTypes.bool.isRequired,
    showEventLink: PropTypes.bool.isRequired
};
module.exports = ParcoursTargetTrackPage;
