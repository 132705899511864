/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');

const {useMapsLibrary} = require("@vis.gl/react-google-maps");
const BaseMap = require("components/maps/BaseMap");
const TargetMapMarker = require("components/maps/TargetMapMarker");
const BasicMapMarker = require("components/maps/BasicMapMarker");
const TargetTrackLine = require("components/maps/TargetTrackLine");
const MapUtil = require("components/map/MapUtil");
const mapUtil = new MapUtil();
const ErrorBoundary = require("components/widgets/ErrorBoundary");

const TargetMapFrame = ({targets, parcours, mapEventTracks}) => {
    let parcoursCoords = null;
    let center = {lat: 48.249863, lng: 14.635051}
    const targetBounds = mapUtil.buildTargetBounds(targets);
    if (parcours && parcours.getParcoursOwnerID()) {
        parcoursCoords = parcours.getParcoursOwnerID().getCoordinates();
        if (parcoursCoords) {
            center = {lat: parcoursCoords._latitude, lng: parcoursCoords._longitude}
            targetBounds.push({
                id: parcours.id,
                coordinate: parcoursCoords
            })
        }
    }
    return (
        <BaseMap mapOptions={{ center: center, mapID:"TMF_MAP",
                containerStyle: {width: "100%", height: '500px'},
                zoom: 8, mapTypeId:"satellite"}} elements={targetBounds}>
            <MapsLibWrapper targets={targets} mapEventTracks={mapEventTracks}/>
            {
                parcoursCoords != null ? <BasicMapMarker coordinate={parcoursCoords} icon="/img/ico/archery.png" /> : null
            }
        </BaseMap>
    )
};
TargetMapFrame.propTypes = {
    targets:PropTypes.array.isRequired,
    mapEventTracks:PropTypes.array,
    parcours:PropTypes.object
};
const MapsLibWrapper = ({targets, mapEventTracks}) => {
    const maps = useMapsLibrary("maps");
    if (!maps) {
        return null;
    }
    return <React.Fragment>
        {targets.map(target => {
            if (target.getCoordinates()) {
                return <ErrorBoundary key={target.id} identifier={"Target:" + target.id}>
                    <TargetMapMarker key={target.id} parcoursTarget={target} maps={maps}/>
                </ErrorBoundary>
            }
        })
    }
        {
            mapEventTracks != null ? mapEventTracks.map(eventTrack => {
                const trackArr = eventTrack.getTrack();
                if (trackArr != null) {
                    const trackElements = mapUtil.buildTrackObject(trackArr, null, [], null, 0)
                    return trackElements.map( trackElement => {
                        return <ErrorBoundary key={eventTrack.id + trackElement.targetNumber} identifier={"Target:" + trackElement.targetNumber}>
                            <TargetTrackLine coordArray={trackElement.coordArray} maps={maps}
                                             targetName={trackElement.targetName}
                                             targetPoints={trackElement.targetPoints}
                                             targetNumber={trackElement.targetNumber}
                                             posMarker={trackElement.posMarker}/>
                        </ErrorBoundary>
                    })
                }
            }) : null
        }
    </React.Fragment>
}
module.exports = TargetMapFrame;