const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const messages = require("i18n/messages");
const ValidInput = require("components/form/ValidInput");
const FontAwesome = require("components/widgets/FontAwesome"),
    MapEditableFrame = require("components/maps/MapEditableFrame");

class CoordinateMultipleInput extends React.Component {
    constructor(props) {
        super(props);
        this.refMainCoord = React.createRef();
        this.refAddCoord = React.createRef();

        this.getValue = this.getValue.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.dragEndMainCoord = this.dragEndMainCoord.bind(this);
        this.dragEndAddCoord1 = this.dragEndAddCoord1.bind(this);

        let coordBean = {coord: null};
        let addCoord1Bean = {coord: null};
        let mapID = "map_id_std";
        if (this.props.objectWithCoords) {
            mapID = "map" + this.props.objectWithCoords.id;
        }
        if (this.props.objectWithCoords && this.props.objectWithCoords.getCoordinates()) {
            coordBean.coord = this.props.objectWithCoords.getReadableCoordinates();
        } else if (this.props.startPoint && this.props.startPoint.getCoordinates()) {
            coordBean.coord = this.props.startPoint.getReadableCoordinates();
        }
        coordBean.dragEnd = this.dragEndMainCoord;
        coordBean.iconUrl = "/img/ico/target.png";
        if (this.props.objectWithCoords && this.props.objectWithCoords.getCoordinatesPegs()) {
            let coordPegs =  this.props.objectWithCoords.getReadableCoordinatesPegs();
            addCoord1Bean.coord = coordPegs[0];
        } else if (this.props.startPoint && this.props.startPoint.getCoordinates()) {
            addCoord1Bean.coord = this.props.startPoint.getReadableCoordinates();
        }
        addCoord1Bean.dragEnd = this.dragEndAddCoord1;
        addCoord1Bean.iconUrl = "/img/ico/archery.png";
        this.state = {
            mapID: mapID,
            mapMainCoord: coordBean,
            mapAddCoord1: addCoord1Bean
        }
    }
    onFormSubmit(e) {
        e.preventDefault();
        let eMCoord = Object.assign({}, this.state.mapMainCoord);
        eMCoord.coord =  this.refMainCoord.current.getValue().trim();
        let eACoord = Object.assign({}, this.state.mapAddCoord1);
        eACoord.coord =  this.refMainCoord.current.getValue().trim();
        this.setState({
            mapMainCoord: eMCoord,
            mapAddCoord1: eACoord
        });
        if (this.props.onSubmit) {
            this.props.onSubmit(e);
        }
    }
    getValue() {
        return {
            mainCoord: this.refMainCoord.current.getValue().trim(),
            addCoord1: this.refAddCoord.current.getValue().trim()
        };
    }
    showHelpMessage(message, style) {
        this.refMainCoord.current.showHelpMessage(message, style)
    }
    dragEndMainCoord(lat, long) {
        this.refMainCoord.current.setValue(lat + "," + long);
    }
    dragEndAddCoord1(lat, long) {
        this.refAddCoord.current.setValue(lat + "," + long);
    }
    render() {
        let saveButton = null;
        if (this.props.onSubmit) {
            saveButton = <Button type="submit"><FontAwesome icon="save"/></Button>
        }
        return (
            <form action="#" onSubmit={this.onFormSubmit}>
                <ValidInput ref={this.refMainCoord}
                            label={messages.get("parcours.details.area.coordinates") + "-" + messages.get("tournament.table.column.target")}
                            valid={{maxLength: 50, check:['isCoordinate']}}
                            addonBefore={<FontAwesome icon="bullseye"/> }
                            default={this.state.mapMainCoord.coord}

                            buttonAfter={saveButton}/>
                <ValidInput ref={this.refAddCoord}
                            label={messages.get("parcours.details.area.coordinates") + "-" + messages.get("parcours.targets.input.peg")}
                            addonBefore={<FontAwesome icon="male"/> }
                            default={this.state.mapAddCoord1.coord}/>
                {this.state.mapMainCoord.coord != null ?
                    <MapEditableFrame mapID={this.state.mapID} eventTrack={this.props.eventTrack} targetIndex={this.props.objectWithCoords.getIndex()}
                                  mapCoordinates={[this.state.mapMainCoord, this.state.mapAddCoord1]}/> :
                    <p>{messages.get("parcours.details.area.coordinate.missing")}</p>}
            </form>
        )
    }
}
CoordinateMultipleInput.propTypes = {
    objectWithCoords: PropTypes.object.isRequired,
    eventTrack: PropTypes.object,
    startPoint: PropTypes.object,
    onSubmit:PropTypes.func,
};
module.exports = CoordinateMultipleInput;
