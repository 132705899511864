const React = require("react"),
    PropTypes = require('prop-types');
const {InputGroup, FormGroup, ControlLabel} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");
const AsyncTypeahead = require('react-bootstrap-typeahead').AsyncTypeahead;

class SelectInputAsyncTypeAhead extends React.Component {
    constructor(props) {
        super(props);
        this.refUTSearchSelect = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.renderMenuItem = this.renderMenuItem.bind(this);
        this.state = {
            loading: false,
            searchResult: []
        }
    }
    componentDidMount() {
        if (this.refUTSearchSelect.current != null) {
            this.refUTSearchSelect.current.focus()
        }
    }
    onSearch(searchTxt) {
        this.setState({loading: true});
        this.props.onSearch(searchTxt.trim(), callback => {
            this.setState({
                loading: false,
                searchResult: callback
            })
        })
    }
    handleChange(selObjectsArr) {
        if (selObjectsArr.length == 1) {
            this.props.handleSelectedObject(selObjectsArr[0]);
        }
    }
    clearInput() {
        this.refUTSearchSelect.current.clear();
    }
    renderMenuItem(option) {
        if (this.props.renderOptionItems) {
            return this.props.renderOptionItems(option);
        } else {
            return option.getDatalistText()
        }
    }
    render() {
        const {placeholder, disabled, label} = this.props;
        const {searchResult, loading} = this.state;
        return  (
            <form onSubmit={this.handleSubmitE}>
                <FormGroup>
                    {
                        label != null ? <ControlLabel>{label}</ControlLabel> : null
                    }
                <InputGroup>
                    <InputGroup.Addon>
                        <FontAwesome icon="search"/>
                    </InputGroup.Addon>

                    <AsyncTypeahead ref={this.refUTSearchSelect}
                        disabled={disabled}
                        labelKey={object => object.getDatalistText ? object.getDatalistText() : ""}
                        minLength={3}
                        options={searchResult}
                        onSearch={this.onSearch}
                        isLoading={loading}
                        placeholder={placeholder}
                        allowNew={false}
                        multiple={false}
                        emptyLabel={false}
                        onChange={this.handleChange}
                        renderMenuItemChildren={this.renderMenuItem}
                    />
                </InputGroup>
                </FormGroup>
            </form>)
    }
}
SelectInputAsyncTypeAhead.propTypes = {
    placeholder:PropTypes.string,
    disabled:PropTypes.bool,
    label: PropTypes.any,
    onSearch: PropTypes.func.isRequired,
    renderOptionItems: PropTypes.func,
    handleSelectedObject:PropTypes.func.isRequired
};
module.exports = SelectInputAsyncTypeAhead;
