const React = require("react"),
    PropTypes = require('prop-types');

const messages = require("i18n/messages");
const {useMap, useAdvancedMarkerRef, AdvancedMarker, Pin, InfoWindow} = require("@vis.gl/react-google-maps");

const TargetTrackLine = ({coordArray, targetNumber, targetName, targetPoints, posMarker, maps}) => {
    const map = useMap();
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [infoWindowShown, setInfoWindowShown] = React.useState(false);

    // clicking the marker will toggle the info window
    const handleMarkerClick = React.useCallback(
        () => {
            setInfoWindowShown(isShown => !isShown)
        },
        []
    );
    // if the maps api closes the info window, we have to synchronize our state
    const handleClose = React.useCallback(() => setInfoWindowShown(false), []);
    function buildCoordPath(coordArray) {
        let path = [];
        coordArray.map(coordinate => {
            if (coordinate) {
                path.push({lat: coordinate.la, lng: coordinate.lo})
            }
        });
        return path
    }
    let targetPath = new maps.Polyline({
        path: buildCoordPath(coordArray),
        geodesic: true,
        strokeColor:"#FF0000",
        strokeOpacity: 1.0,
        strokeWeight:3,
        icons: [{
            icon: {path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW},
            offset: '100%'
        }]
    });
    targetPath.setMap(map)
    return (
        <React.Fragment>
            {
                posMarker != null ? <AdvancedMarker ref={markerRef} position={{lat:  posMarker.la, lng:  posMarker.lo}}
                                                    onClick={handleMarkerClick}>
                    {/*<img src={"/img/ico/frame.png"} width={32} height={37} />*/}
                    <Pin glyphColor={'#ffffff'} background={'#0fbf02'} borderColor={'#000000'}
                         glyph={targetNumber} scale={1.2} />
                </AdvancedMarker> : null
            }

            {infoWindowShown && (
                <InfoWindow anchor={marker} onClose={handleClose}
                    headerContent={<h4>{messages.get("tournament.table.column.target")} {targetNumber}</h4>}>
                    <React.Fragment>
                        {targetName ? <p><strong>{targetName}</strong></p> : null}
                        {
                            targetPoints != null ? targetPoints.map(entry => {
                                return <p>{entry}</p>
                            }) : null
                        }
                    </React.Fragment>
                </InfoWindow>
            )}
        </React.Fragment>
    );
}
module.exports = TargetTrackLine;