const React = require("react"),
    PropTypes = require('prop-types');

const {AdvancedMarker , useAdvancedMarkerRef} = require('@vis.gl/react-google-maps');

const MapMarkerContent = require("components/maps/MapMarkerContent");

const ParcoursMapMarker = ({mapElement, clusterer}) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [infoWindowShown, setInfoWindowShown] = React.useState(false);

    // clicking the marker will toggle the info window
    const handleMarkerClick = React.useCallback(
        () => {
            setInfoWindowShown(isShown => !isShown)
        },
        []
    );
    // if the maps api closes the info window, we have to synchronize our state
    const handleClose = React.useCallback(() => setInfoWindowShown(false), []);
    const posMarker = {lat:  mapElement.coordinate._latitude, lng: mapElement.coordinate._longitude};
    return (
        <React.Fragment>
            <AdvancedMarker ref={markerRef} position={posMarker} onClick={handleMarkerClick}>
                <img src={mapElement.markerIcon} width={32} height={37} />
            </AdvancedMarker>
            {infoWindowShown && (
                <MapMarkerContent anchor={marker} onClose={handleClose} mapElement={mapElement}/>
            )}
        </React.Fragment>
    );
}
ParcoursMapMarker.propTypes = {
    mapElement: PropTypes.object.isRequired,
    clusterer: PropTypes.object
};
module.exports = ParcoursMapMarker;