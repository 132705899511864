const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const messages = require("i18n/messages");
const ValidInput = require("components/form/ValidInput");
const FontAwesome = require("components/widgets/FontAwesome"),
    MapEditableFrame = require("components/maps/MapEditableFrame");

class CoordinateInput extends React.Component {
    constructor(props) {
        super(props);
        this.refMainCoord = React.createRef();
        
        this.getValue = this.getValue.bind(this);
        this.onCoordinateSearch = this.onCoordinateSearch.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.dragEndMainCoord = this.dragEndMainCoord.bind(this);
        this.getEmptyMainCoord = this.getEmptyMainCoord.bind(this);

        let coordBean = this.getEmptyMainCoord();
        let mapID = "map_id_std";
        if (this.props.objectWithCoords) {
            mapID = "map" + this.props.objectWithCoords.id;
        }
        if (this.props.objectWithCoords && this.props.objectWithCoords.getCoordinates()) {
            coordBean.coord = this.props.objectWithCoords.getReadableCoordinates();
        } else if (this.props.startPoint && this.props.startPoint.getCoordinates()) {
            coordBean.coord = this.props.startPoint.getReadableCoordinates();
        }
        this.state = {
            mapID: mapID,
            searchObj: null,
            mapMainCoord: coordBean
        }
    }
    getEmptyMainCoord() {
        let coordBean = {coord: null, iconUrl: null}
        coordBean.dragEnd = this.dragEndMainCoord;
        return coordBean;
    }
    onCoordinateSearch(e) {
        e.preventDefault();
        // reset store
        this.setState({searchObj : null, mapMainCoord: this.getEmptyMainCoord()}, () => {
            // start new search
            const nSearchObj = {searchString: this.getValue()};
            nSearchObj.dragEnd = this.dragEndMainCoord;
            this.setState({searchObj: nSearchObj});
        })
    }
    onFormSubmit(e) {
        e.preventDefault();
        if (this.props.onSubmit) {
            this.props.onSubmit(this, this.getValue());
        }
    }
    getValue() {
        return this.refMainCoord.current.getValue().trim();
    }
    showHelpMessage(message, style) {
        this.refMainCoord.current.showHelpMessage(message, style)
    }
    dragEndMainCoord(lat, long) {
        this.refMainCoord.current.setValue(lat + "," + long);
        let eCoord = Object.assign({}, this.state.mapMainCoord);
        eCoord.coord = lat + "," + long;
        this.setState({mapMainCoord: eCoord, searchObj: null});
    }
    render() {
        let saveButton = null;
        if (this.props.onSubmit) {
            saveButton = <Button type="submit"><FontAwesome icon="save"/></Button>
        }
        return (
            <form action="#" onSubmit={this.onFormSubmit}>
                <ValidInput ref={this.refMainCoord}
                            label={messages.get("parcours.details.area.coordinates")}
                            valid={{maxLength: 50, check:['isCoordinate']}}
                            default={this.state.mapMainCoord.coord}
                            buttonAfter={[<Button onClick={this.onCoordinateSearch}><FontAwesome icon="search"/></Button>,saveButton]}/>
                {this.state.mapMainCoord.coord != null  || this.state.searchObj ? <MapEditableFrame mapID={this.state.mapID}
                                                                      searchObj={this.state.searchObj}
                                                                  mapCoordinates={[this.state.mapMainCoord]}/> : ""}
            </form>
        )
    }
}
CoordinateInput.propTypes = {
    objectWithCoords: PropTypes.object.isRequired,
    startPoint: PropTypes.object,
    onSubmit:PropTypes.func,
};
module.exports = CoordinateInput;
