const React = require("react"),
    PropTypes = require('prop-types'),
    Parse = require("ParseInit");
const {FormGroup, Alert, ControlLabel, FormControl, Button, ButtonGroup} = require("react-bootstrap");
const messages = require("i18n/messages"),
    Loading = require("components/widgets/Loading"),
    Utility = require("util/Utility"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    NumberRangeInput = require("components/form/NumberRangeInput"),
    FontAwesome = require("components/widgets/FontAwesome");

const AvatarEditor = require('react-avatar-editor');

class AvatarFileUploadInput extends React.Component {
    constructor(props) {
        super(props);
        this.refAvaEditor = React.createRef();

        this.fileSelected = this.fileSelected.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.checkValidFileSizeType = this.checkValidFileSizeType.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.checkFileExtenstion = this.checkFileExtenstion.bind(this);
        this.saveImage = this.saveImage.bind(this);
        this.changeScale = this.changeScale.bind(this);
        this.resetImage = this.resetImage.bind(this);
        this.state = {
            uploadImage: null,
            imgScale: 12,
            help: null,
            loading: false,
            style: "success"
        }
    }
    fileSelected(e) {
        console.log("File selected");
        let files = e.target.files;
        this.handleFileUpload(files);
    }
    setLoading(loading) {
        this.setState({
            loading: loading
        });
    }
    handleFileUpload(files) {
        if (files != null && files.length == 1) {
            // upload file
            let file = files[0];
            if (this.checkValidFileSizeType(file) != null) {
                // file valid
                this.setState({uploadImage: file, loading: false});
            }
        } else {
            this.showHelpMessage(messages.get("fileupload.selectFile"),"danger");
        }
    }
    checkValidFileSizeType(file) {
        console.log("validate " + file.name +  " size: " + file.size);
        this.setLoading(true);
        // check file size
        if (file.size > 5242880) {
            this.showHelpMessage(file.name + " - " + messages.get("fileupload.selectFileToLarge"),"danger");
            return null;
        }
        // check file extenstion
        let validMsg = [];
        const validImageExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
        if (this.checkFileExtenstion(validImageExtensions, file.name)) {
            return "I";
        } else {
            validMsg.push(validImageExtensions)
        }
        this.showHelpMessage(file.name + " - " + messages.get("fileupload.selectFileInvalidExt") + validMsg.join(", "), "danger");
        return null;
    }
    showHelpMessage(message, style) {
        const that = this;
        this.setState({
            help: message,
            loading: false,
            style: style
        });
        setTimeout(function () {
            that.showHelpMessage(null);
        }, 5000);
    }
    checkFileExtenstion(validFileExtensions, sFileName) {
        for (let j = 0; j < validFileExtensions.length; j++) {
            let sCurExtension = validFileExtensions[j];
            if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                return true;
            }
        }
        return false;
    }
    saveImage() {
        if (this.refAvaEditor.current) {
            this.setState({loading: true});
            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            const that = this;
            const canvasScaled = this.refAvaEditor.current.getImageScaledToCanvas();
            if (canvasScaled) {
                canvasScaled.toBlob((blobScaled) => {
                    let fileName = this.props.fileName ? Utility.cleanInvalidChars(this.props.fileName) : "userImage";
                    let fr = new FileReader();
                    fr.onload = receiveFile;
                    fr.readAsDataURL(blobScaled);
                    function receiveFile() {
                        let result = fr.result;
                        let res = result.split("base64,");
                        // save parse file
                        let parseFile = new Parse.File(fileName + ".png", { base64: res[1] });
                        parseFile.save()
                            .then(sParseFile => {
                                that.props.savedParseFileCallback(sParseFile);
                            })
                            .catch(error => {
                                console.log("BASE_sFile "+ error.message);
                                that.showHelpMessage("Could not store File " + error.message, "danger");
                            });
                    }
                },"image/png",1)

            }
        }
    }
    changeScale(scale) {
        this.setState({imgScale: scale[0]})
    }
    resetImage() {
        this.setState({uploadImage: null, loading: false})
    }
    render() {
        const {label} = this.props;
        const {uploadImage, imgScale, loading} = this.state;
            return (
                <React.Fragment>
                    {
                        this.state.uploadImage ?
                            <ErrorBoundary>
                                <AvatarEditor ref={this.refAvaEditor}
                                    image={uploadImage}
                                    width={175}
                                    height={175}
                                    border={[25,25]}
                                    color={[255, 255, 255, 0.6]} // RGBA
                                    scale={imgScale / 10}
                                    rotate={0}
                                />
                                <div style={{margin:"15px"}}>
                                    <NumberRangeInput markerAmount={1} min={1} max={50} step={1}
                                                      defaultValue={imgScale}
                                                      onAfterChange={(value) => this.changeScale(value)}/>
                                </div>
                            </ErrorBoundary> : null
                    }
                    {
                        uploadImage == null ?
                            <FormGroup>
                                <ControlLabel>{label}</ControlLabel>
                                <FormControl bsStyle="primary" type='file'  ref='fileUpload' onChange={this.fileSelected}/><br/>
                                {
                                    loading === true ? <Loading /> : null
                                }
                            </FormGroup>
                            :
                            loading === true ? <Loading /> :
                                <ButtonGroup>
                                    <Button onClick={this.saveImage}><FontAwesome icon="check"/></Button>
                                    <Button onClick={this.resetImage}><FontAwesome icon="times"/></Button>
                                </ButtonGroup>

                    }
                    <br/><br/>
                    {
                        this.state.help == null ? "" :
                            <Alert bsStyle={this.state.style}>
                                {this.state.help}
                            </Alert>
                    }
                </React.Fragment>
            )
    }
}
AvatarFileUploadInput.propTypes = {
    label: PropTypes.string,
    fileName: PropTypes.string,
    savedParseFileCallback:PropTypes.func.isRequired
};

module.exports = AvatarFileUploadInput;