const Parse = require("ParseInit");

const BowUnion = Parse.Object.extend("bowUnion", {
    col: {
        ACL: "ACL",
        ageType: "ageType",
        bowType: "bowType",
        code: "code",
        createdAt: "createdAt",
        mainGroupPlaceID: "mainGroupPlaceID",
        name: "name",
        objectId: "objectId",
        mandLicNr: "mandLicNr",
        autoLicNr: "autoLicNr",
        licLabel: "licLabel",
        licRegex: "licRegex",
        sortIndex: "sortIndex",
        cupTypes: "cupTypes",
        standaloneTypes: "tTypes", // single tournament types
        weblink: "weblink",
        minCupResults: "minCupResults",
        desc:"desc",
        logo: "logo",
        cupPoints: "cupPoints",
        baseMonth: "baseMonth", // if enabled - best result per month is used
        baseTargets: "baseTargets", // Result will be based to this amount of targets instead of cupPoints
        showDPS: "showDPS", // if enabled - list show actual DPS
        cupMap: "cupMap", // if enabled - show link to map with all parcours of tournament
        cupGroups:"cupGroups", // Array of string to group cup children together
        ageConfigCalc:"showAgeConfigCalc", //if enabled - show function for the age config calculator
        ageConfigYearOnly:"ageConfigYearOnly", // if enabled - the start of the year counts not the tournament date
        status: "status",
        updatedAt: "updatedAt",
    },
    type: {
        single: "S",
        cup: "C",
        standalone: "ST" // standalone tournament
    },
    cupType: {
        cupMaster: "CM",
        cupChild: "CC"
    },
    hardcodedUnionIDs: {
        ownRulesProd: "2GZlUSYLin",
        ownRulesDev: "O6oS6JO5Nt",
        lippek: "OmbBsUHNJp",
        breitensport: "EKmmetFAq7"
    },
    getSelectString() {
        return this.getCode() + ", " + this.getName()
    },
    isLicenseNumberMandatory() {
        return this.get(this.col.mandLicNr) == true;
    },
    isAutoLicenseNumber() {
        return this.get(this.col.autoLicNr) == true;
    },
    isTypeLippek() {
        return this.id === this.hardcodedUnionIDs.lippek;
    },
    isShowDPS() {
        return this.get(this.col.showDPS);
    },
    isTypeCup() {
        return this.getCupTypes() != null;
    },
    isTypeStandalone() {
        return this.getStandaloneType() != null;
    },
    getCupTypes() {
        return this.get(this.col.cupTypes);
    },
    getStandaloneType() {
        return this.get(this.col.standaloneTypes);
    },
    showCupMap() {
        return this.get(this.col.cupMap)
    },
    isBaseMonth() {
        return this.get(this.col.baseMonth)
    },
    showAgeConfigCalc() {
        return this.get(this.col.ageConfigCalc)
    },
    showAgeConfigCalcYearOnly() {
        return this.get(this.col.ageConfigYearOnly)
    },
    getCupTypesTranslatable() {
        if (this.getCupTypes()) {
            const result = [];
            this.getCupTypes().map(cupType => {
                result.push("bowUnion.type." + cupType);
            });
            return result;
        }
        return null;
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAgeType() {
        return this.get(this.col.ageType)
    },
    setAgeType(ageType) {
        return this.set(this.col.ageType, ageType)
    },
    getBowType() {
        return this.get(this.col.bowType)
    },
    setBowType(bowType) {
        return this.set(this.col.bowType, bowType)
    },
    getCode() {
        return this.get(this.col.code)
    },
    setCode(code) {
        return this.set(this.col.code, code)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getMainGroupPlaceID() {
        return this.get(this.col.mainGroupPlaceID)
    },
    setMainGroupPlaceID(mainGroupPlaceID) {
        return this.set(this.col.mainGroupPlaceID, mainGroupPlaceID)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getLicenseLabel() {
        let label =  this.get(this.col.licLabel);
        if (label == null && this.isAutoLicenseNumber()) {
            return this.getName();
        }
        return label;
    },
    getSortIndex() {
        return this.get(this.col.sortIndex)
    },
    setSortIndex(sortIndex) {
        return this.set(this.col.sortIndex, sortIndex)
    },
    getCupGroups() {
        return this.get(this.col.cupGroups)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getMinCupResults() {
        return this.get(this.col.minCupResults)
    },
    setMinCupResults(minCupResults) {
        return this.set(this.col.minCupResults, minCupResults)
    },
    getBaseTargets() {
        return this.get(this.col.baseTargets);
    },
    setBaseTargets(targetAmount) {
        return this.set(this.col.baseTargets, targetAmount);
    },
    getCupPoints() {
        return this.get(this.col.cupPoints)
    },
    setCupPoints(cupPointArray) {
        return this.set(this.col.cupPoints, cupPointArray)
    },
    getLogo() {
        return this.get(this.col.logo);
    },
    getLogoUrl() {
        let logo = this.getLogo();
        if (logo != null) {
            return logo.url();
        } else {
            return "";
        }
    },
    getLicenseRegex() {
        return this.get(this.col.licRegex)
    },
    getDescription: function() {
        return this.get(this.col.desc)
    },
    getWeblink() {
        return this.get(this.col.weblink)
    },
    setWeblink(weblink) {
        return this.set(this.col.weblink, weblink)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = BowUnion;
