const React = require("react"),
    PropTypes = require('prop-types');

const {useAdvancedMarkerRef, AdvancedMarker, Pin, useMap} = require("@vis.gl/react-google-maps");
const TargetMarkerContent = require("components/maps/TargetMarkerContent");

const TargetMapMarker = ({parcoursTarget, maps}) => {
    const map = useMap();
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [infoWindowShown, setInfoWindowShown] = React.useState(false);

    // clicking the marker will toggle the info window
    const handleMarkerClick = React.useCallback(
        () => {
            setInfoWindowShown(isShown => !isShown)
        },
        []
    );
    // if the maps api closes the info window, we have to synchronize our state
    const handleClose = React.useCallback(() => setInfoWindowShown(false), []);
    const tCoord = parcoursTarget.getCoordinates();
    const posMarker = {lat:  tCoord.latitude, lng:  tCoord.longitude};
    let targetPath = null;
    if (parcoursTarget.getCoordinatesPegs() && parcoursTarget.getCoordinatesPegs().length > 0) {
        const pCoord = parcoursTarget.getCoordinatesPegs()[0];
        if (pCoord) {
            targetPath = new maps.Polyline({
                path: [
                    {lat: pCoord._latitude, lng: pCoord._longitude},
                    {lat:  tCoord.latitude, lng:  tCoord.longitude}
                ],
                geodesic: true,
                strokeColor:"#0000FF",
                strokeOpacity: 1.0,
                strokeWeight:3,
                icons: [{
                    icon: {path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW},
                    offset: '100%'
                }]
            });
            targetPath.setMap(map)
        }
    }
    return (
        <React.Fragment>
            <AdvancedMarker ref={markerRef} position={posMarker} onClick={handleMarkerClick}>
                {/*<img src={"/img/ico/frame.png"} width={32} height={37} />*/}
                <Pin glyphColor={'#ffffff'} background={'#0fbf02'} borderColor={'#000000'}
                     glyph={parcoursTarget.getIndex().toString()} scale={1.2} />
            </AdvancedMarker>
            {infoWindowShown && (
                <TargetMarkerContent anchor={marker} onClose={handleClose} mapElement={parcoursTarget} />
            )}
        </React.Fragment>
    );
}
TargetMapMarker.propTypes = {
    parcoursTarget: PropTypes.object.isRequired
};
module.exports = TargetMapMarker;