const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Panel, Button} = require("react-bootstrap");

const Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    RadioGroupInput = require("components/form/RadioGroupInput"),
    SubmitButton = require("components/form/SubmitButton"),
    CheckboxArray = require("components/form/CheckboxArray"),
    ValidInput = require("components/form/ValidInput"),
    messages = require("i18n/messages");
const {BowUnion} = require("parse/_Domain");

const licenseStore = require("stores/LicenseStore"),
    licenseActions = require("actions/LicenseActions"),
    tournamentActions = require("actions/TournamentManagerActions"),
    notificationActions = require("components/notification/NotificationActions");

class TournamentUnionSelection extends React.Component {
    constructor(props) {
        super(props);
        this.handleLicenseChange = this.handleLicenseChange.bind(this);

        this.state = {
            licenseStore: licenseStore.getState()
        }
    }
    componentDidMount() {
        licenseStore.listen(this.handleLicenseChange);
        licenseActions.loadBowUnionsAndTypes.defer(null);
    }
    componentWillUnmount() {
        licenseStore.unlisten(this.handleLicenseChange);
    }
    handleLicenseChange(state) {
        this.setState({licenseStore: state});
    }
    render() {
        if (this.state.licenseStore.loading == true) {
            return (
                <SelectionPanel>
                    <Row>
                        <Col lg={12}><Loading/></Col>
                    </Row>
                </SelectionPanel>)
        }
        return (
            <SelectionPanel>
                <SelectionBody
                    tournament={this.props.tournament}
                    bowUnions={this.state.licenseStore.bowUnions}
                    bowTypes={this.state.licenseStore.bowTypes}
                    ageTypes={this.state.licenseStore.ageTypes}/>
            </SelectionPanel>)
    }
}
TournamentUnionSelection.propTypes = {
    tournament:PropTypes.object.isRequired
};
const SelectionPanel = ({children}) => {
    return (<Col sm={12}>
        <Panel>
            <Panel.Heading>
                <h2><FontAwesome icon="book"><I18n code="tournament.manager.config.bowUnion"/></FontAwesome></h2>
            </Panel.Heading>
            <Panel.Body>{children}</Panel.Body>
        </Panel>
    </Col>)
};
class SelectionBody extends React.Component {
    constructor(props) {
        super(props);
        this.refCupMasterLink = React.createRef();

        this.bowUnionTypeChanged = this.bowUnionTypeChanged.bind(this);
        this.bowUnionSubTypeChanged = this.bowUnionSubTypeChanged.bind(this);
        this.filterBowUnion = this.filterBowUnion.bind(this);
        this.handleBowUnionChange = this.handleBowUnionChange.bind(this);
        this.generateTypeDict = this.generateTypeDict.bind(this);
        this.handleBowTypeChange = this.handleBowTypeChange.bind(this);
        this.handleAgeTypeChange = this.handleAgeTypeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createConfigTypeArray = this.createConfigTypeArray.bind(this);
        this.onCupMasterSearch = this.onCupMasterSearch.bind(this);
        this.parseTournamentID = this.parseTournamentID.bind(this);

        this.state = {
            bowUnionType: BowUnion.prototype.type.single,
            bowUnionSubType: null,
            bowUnion: null,
            selAgeTypes: null,
            selBowTypes: null,
            cupMasterTournament: null
        }
    }
    bowUnionTypeChanged(selected) {
        this.setState({
            bowUnion: null,
            bowUnionSubType: null,
            bowUnionType: selected.split(".")[2],
            cupMasterTournament: null
        });
    }
    bowUnionSubTypeChanged(selected) {
        this.setState({
            bowUnionSubType: selected.split(".")[2],
            cupMasterTournament: null
        }, () => {
            // autoSearch for master tournament
            if (selected === "bowUnion.type.CC") {
                tournamentActions.findTournamentCupMaster(this.state.bowUnion, (tournamentCupMaster => {
                    this.setState({ cupMasterTournament: tournamentCupMaster});
                }))
            }
        });
    }
    filterBowUnion() {
        return this.props.bowUnions.filter(bowUnion => {
            if (this.state.bowUnionType === BowUnion.prototype.type.single) {
                return bowUnion.isTypeStandalone();
            } else {
                return bowUnion.isTypeCup();
            }
        })
    }
    handleBowUnionChange(bowUnionID) {
        let ageTypes = this.generateTypeDict(this.props.ageTypes, bowUnionID);
        let bowTypes = this.generateTypeDict(this.props.bowTypes, bowUnionID);
        this.setState({
                bowUnion: bowUnionID,
                bowUnionSubType: null,
                selAgeTypes: ageTypes,
                selBowTypes:bowTypes
            });
    }
    generateTypeDict(typeArray, bowUnion) {
        const typeDict = {};
        if (bowUnion != null && typeArray != null) {
            typeArray.map(type => {
                if (bowUnion.getCode() == "-"){
                    typeDict[type.id] = {
                        selected: false,
                        name: type.getSelectString()
                    }
                } else {
                    if (type.getUnions() != null && type.getUnions().indexOf(bowUnion.getCode()) > -1) {
                        typeDict[type.id] = {
                            selected: true,
                            name: type.getSelectString()
                        }
                    }
                }
            });
        }
        return typeDict;
    }
    handleBowTypeChange(checked, elementKey) {
        let dic = this.state.selBowTypes;
        dic[elementKey].selected = checked;
        this.setState({selBowTypes: dic});
    }
    handleAgeTypeChange(checked, elementKey) {
        let dic = this.state.selAgeTypes;
        dic[elementKey].selected = checked;
        this.setState({selAgeTypes: dic});
    }
    handleSubmit() {
        // set bowUnion and create bow and age types like template
        if (this.state.bowUnionSubType == BowUnion.prototype.cupType.cupChild) {
            tournamentActions.updateBowUnionForCupMaster(this.props.tournament, this.state.bowUnion, this.state.bowUnionType,
                this.state.cupMasterTournament);
        } else {
            tournamentActions.updateBowUnionwithTypes(this.props.tournament, this.state.bowUnion, this.state.bowUnionType,
                this.createConfigTypeArray(this.state.selBowTypes, this.props.bowTypes),
                this.createConfigTypeArray(this.state.selAgeTypes, this.props.ageTypes));
        }

    }
    createConfigTypeArray(cbTypeDic, fullTypeList) {
        let typeArray = null;
        if (cbTypeDic) {
            typeArray = [];
            Object.keys(cbTypeDic).map(cbTypeKey => {
                if (cbTypeDic[cbTypeKey].selected) {
                    fullTypeList.map(type => {
                        if (type.id == cbTypeKey) {
                            typeArray.push(type);
                        }
                    });
                }
            })
        }
        return typeArray;
    }
    onCupMasterSearch() {
        const cupMasterLink = this.refCupMasterLink.current.getValue().trim();
        const tCupMasterId = this.parseTournamentID(cupMasterLink.split("/"));
        if (tCupMasterId) {
            tournamentActions.loadTournamentCupMaster(tCupMasterId,this.state.bowUnion, (tournamentCupMaster => {
                this.setState({ cupMasterTournament: tournamentCupMaster});
            }))
        } else {
            notificationActions.warning("NoIDinUrlFound");
        }
    }
    parseTournamentID(linkArray) {
        let foundTournament = false;
        if (linkArray) {
            for (let i = 0; i < linkArray.length; i++) {
                if (!foundTournament) {
                    if (linkArray[i] == "tournament") {
                        foundTournament = true;
                    }
                } else {
                    return linkArray[i];
                }
            }
        }
        return null;
    }
    render() {
        const showTypes = (this.state.bowUnion && this.state.bowUnionType == BowUnion.prototype.type.single)
            || (this.state.bowUnionType == BowUnion.prototype.type.cup && this.state.bowUnionSubType == BowUnion.prototype.cupType.cupMaster)
        const cupChildhasMaster= (this.state.bowUnionSubType == BowUnion.prototype.cupType.cupChild && this.state.cupMasterTournament != null);
        return (<Row>
            <Col xs={12}>
                <RadioGroupInput default={"bowUnion.type." + this.state.bowUnionType}
                                 list={["bowUnion.type." + BowUnion.prototype.type.single, "bowUnion.type." + BowUnion.prototype.type.cup]}
                                 onAfterChange={this.bowUnionTypeChanged}/>
                <hr/>
            </Col>
            <Col xs={12} sm={4}>
                <DropDownParseObject default={this.state.bowUnion}
                                     placeholder={<option key={"nullkey"}
                                                          value={"null"}>{messages.get("modal.license.chooseUnion")}</option>}
                                     onAfterChange={this.handleBowUnionChange}
                                     objectList={this.filterBowUnion()}/>
                {
                    this.state.bowUnionType == BowUnion.prototype.type.cup  && this.state.bowUnion ?
                        <RadioGroupInput default={null}
                                         list={this.state.bowUnion.getCupTypesTranslatable()}
                                         onAfterChange={this.bowUnionSubTypeChanged}/>
                        : null
                }
            </Col>
            {
                showTypes ?
                    <Col xs={12} sm={4}>
                        <I18n code={"modal.license.chooseBow"}/>
                        <CheckboxArray cbEntryDic={this.state.selBowTypes} onAfterChange={this.handleBowTypeChange}/>
                    </Col> : null
            }
            {
                showTypes ?
                    <Col xs={12} sm={4}>
                        <I18n code={"modal.license.chooseAge"}/>
                        <CheckboxArray cbEntryDic={this.state.selAgeTypes} onAfterChange={this.handleAgeTypeChange}/>
                    </Col> : null
            }
            {
                this.state.bowUnionSubType == BowUnion.prototype.cupType.cupChild ?
                    <Col xs={12} sm={8}>
                        <ValidInput ref={this.refCupMasterLink}
                                    placeholder={messages.get("tournament.manager.config.cupMaster")}
                                    valid={{check:['isUrl']}}
                                    buttonAfter={<Button onClick={this.onCupMasterSearch}>
                                        <FontAwesome icon="search"/>
                                    </Button>}/>
                        {
                            this.state.cupMasterTournament ?
                                <p><strong><I18n code="bowUnion.type.CM"/>: </strong>
                                {this.state.cupMasterTournament.getName() +", " + this.state.cupMasterTournament.getTournamentDate()}</p> : null
                        }
                    </Col> : null
            }
            <Col xs={12}><hr/></Col>
            <Col xs={12} sm={4} smOffset={4} className="text-center">
                {
                    this.state.cupMasterTournament ?
                        <React.Fragment>
                            <SubmitButton block bsStyle="primary"
                                          onClick={this.handleSubmit} disabled={!cupChildhasMaster}
                                          icon={<FontAwesome icon="check"/>}
                                          text={messages.get("modal.button.add2cup")}/>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <SubmitButton block bsStyle="primary"
                                          onClick={this.handleSubmit} disabled={!showTypes}
                                          icon={<FontAwesome icon="check"/>}
                                          text={messages.get("modal.button.take")}/>
                            {this.state.bowUnion ? <small><I18n code="tournament.manager.config.bowAgeConfig.desc"/></small> : null}
                        </React.Fragment>
                }
            </Col>
        </Row>)
    }
}
SelectionBody.propTypes = {
    tournament:PropTypes.object.isRequired,
    bowUnions: PropTypes.array.isRequired,
    bowTypes: PropTypes.array.isRequired,
    ageTypes: PropTypes.array.isRequired
};
module.exports = TournamentUnionSelection;
